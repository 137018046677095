import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { BASE_URL } from "./apis/index"
import Swal from 'sweetalert2';

const View = () => {
  const [getval, setGetval] = useState([]);
  const [visibleModal, SetVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [clickedItem, setClickedItem] = useState(null);


  var token = localStorage.getItem("token")?.replaceAll(/"/g,"") || ""
  


console.log("modifyToken>>><<",token)

  const navigate = useNavigate();

  // useEffect(() => {
  //   let config = {
  //     method: "get",

  //     url: `${BASE_URL}/resource/glcAdmin`,

  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       setGetval(response.data);

  //       JSON.stringify(response.data);
  //     })
  //     .catch((error) => {
  //       alert("Error");
  //       console.log(error);
  //     });
  // }, []);


  const fetchResources = useCallback(async () => {
    
    
  
    try {
      setLoading(true)
      // const response = await fetch(`${BASE_URL}/resource/glcAdmin`);
      const response = await fetch(`${BASE_URL}/resource/glcAdmin`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setGetval(responseData);
      } else {
   
        console.error('Request failed with status:', response.status);

      }
  
  
      // const resposneData = await response.json();
      // //   console.log('resposneData>>>>>>>', resposneData);
      // setGetval(resposneData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    fetchResources()
  }, [])



  const handleDeleteAll = () => {

      alert("Are you sure you want to delete all data");

    {
      fetch(`${BASE_URL}/resource-delete-all`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        result.json().then((resp) => {
          console.log(resp);
        });
      });
    }
    window.location.reload();
    
    // setGetval([]);
  };

  const handleResources = () => {
    navigate("/resources");
  };


  const handleAppConfig = () => {
    navigate("/appconfig");
  }

  const ref = useRef(null);

  const updateResource = (selectedItem) => {
    console.log("id>>>>>>", selectedItem);

    setClickedItem(selectedItem);

    SetVisibleModal(true);
    ref.current.click();
  };

  const handleChange = (event) => {
    setClickedItem({
      ...clickedItem,
      [event.target.name]: event.target.value,
    });


  };






  const updateApi = useCallback(async (_id) => {
    ref.current.click();

    if (!clickedItem?.title || (clickedItem?.type !== "Contact" && !clickedItem?.url)) {
      alert('Please fill the data');
      return;
    }

    const modifiedList = {
      method: 'PUT',
      body: JSON.stringify(clickedItem),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    };

    try {
      const response = await fetch(`${BASE_URL}/resource-update/${_id}`, modifiedList);
      const result = await response.json();

      console.log(result, 'iam result');

      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'Resources Edited Successfully',
          showConfirmButton: false,
          timer: 1800,
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 1900);

    } catch (error) {
      console.error('Error occurred during API update:', error);
      // Handle the error or display an error message.
    }
  }, [clickedItem]);





  const handleDelete = useCallback((id) => {
       alert("Are you sure you want to delete");
    setLoading(true)
    fetch(`${BASE_URL}/resource-temp-delete/${id}`, {
      method: "PUT",
            headers: {
         'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp);
      });
    });

    setLoading(false)

    Swal.fire({
      icon: 'success',
      title: 'Resources Deleted Successfully',
      showConfirmButton: false,
      timer: 1800
    });

    setTimeout(() => {
      window.location.reload();
    }, 1900);
  }, []);



  const handlePushNotification = () => {
    navigate("/pushnotificaton");

  }


  return (
    <>
      {/* Modal */}

      <button
        type="button"
        ref={ref}
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Resource
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="my-3">

                <div className="form-outline mb-2">
                  <label htmlFor="title" className="form-label">
                    Select Category
                  </label>
                  <select
                    required
                    class="form-select"
                    id="select"
                    name="type"
                    onChange={handleChange}
                    value={clickedItem?.type}
                  >
                    <option disabled selected>
                      Select type
                    </option>

                    <option>Video</option>
                    <option>Article</option>
                    <option>Contact</option>

                  </select>
                </div>
                <div className="mb-3">
                  {" "}
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input

                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={clickedItem?.title}
                    onChange={handleChange}
                    aria-describedby="emailHelp"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="tag" className="form-label">
                    Subtitle
                  </label>

                  <input

                    type="text"
                    className="form-control"
                    id="subtitle"
                    name="subtitle"
                    value={clickedItem?.subtitle}
                    onChange={handleChange}
                  />
                </div>
                {
                  clickedItem?.type !== "Contact" &&

                  <div className="mb-3">
                    <label htmlFor="tag" className="form-label">
                      URL
                    </label>

                    <input

                      type="text"
                      className="form-control"
                      id="description"
                      name="url"
                      value={clickedItem?.url}
                      onChange={handleChange}
                    />
                  </div>}

                {clickedItem?.type === "Contact" ? (
                  <>
                    <div className="form-outline mt-2 mb-2">
                      <label
                        style={{ marginTop: "6px" }}
                        className="form-label"
                        htmlFor="form3Example4"
                      >
                        Address
                      </label>
                      <input

                        required
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={clickedItem?.address}
                        id="form3Example4"
                        onChange={handleChange}
                        className="form-control"
                      />

                    </div>

                    <div className="form-outline mb-2">
                      <label
                        style={{ marginTop: "6px" }}
                        className="form-label"
                        htmlFor="form3Example4"
                      >
                        Phone Number
                      </label>

                      {/* <span class="input-group-text" id="basic-addon1">
                      +1
                    </span> */}
                      <div style={{ display: "flex" }}>
                        <div style={{
                          backgroundColor: "#e9ecef", width: "40px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"
                          , display: "flex", alignItems: "center", justifyContent: "center", color: "#212529"
                        }}>
                          +1</div>
                        <input required
                          type="text"
                          value={clickedItem?.phoneNumber}
                          name="phoneNumber"
                          placeholder="Phone Number"
                          id="form3Example4"
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>


                    </div>


                  </>
                ) : null}



              </form>

            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#0b8a0b", color: "white" }}
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => updateApi(clickedItem?._id)}
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ color: "#0b8a0b", borderColor: "#0b8a0b" }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>



      {/* 
      Resource Page */}



      <div className="m-4">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button
            onClick={handleResources}
            type="button"
            class="btn"
            style={{ borderColor: "#0b8a0b", color: "#0b8a0b", borderRadius: "3px" }}
          >
            Create Resources
          </button>
          {/* <button
            onClick={handlePushNotification}
            style={{ marginLeft: "10px", borderColor: "#0b8a0b", color: "#0b8a0b", borderRadius: "3px" }}
            type="button"
            class="btn"

          >
            Push Notification
          </button>

          <button
            onClick={handleAppConfig}
            style={{ marginLeft: "10px", borderColor: "#0b8a0b", color: "#0b8a0b", borderRadius: "3px" }}
            type="button"
            class="btn"

          >
            App Configuration
          </button> */}
          <button
            onClick={handleDeleteAll}
            style={{ marginLeft: "10px", borderColor: "#0b8a0b", color: "#0b8a0b", borderRadius: "3px" }}
            type="button"
            class="btn"
          >
            Delete All
          </button>
        </div>
        <center className="">

          {/* >>>>>> */}

          {
            loading && <div style={{ height: loading ?  "100vh" : "0%", display: "flex", alignItems: "center", justifyContent: "center" }}> <div className="spinner-border" style={{ color: "#0b8a0b", alignSelf: "center" }} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            </div>
          }

{
  !loading && 

          <table style={{ marginTop: "10px" }} class="table">

            <thead class="thead-dark">
              <tr>
                {/* <th scope="col">No</th> */}
                <th scope="col">Title</th>
                <th scope="col">Subtitle</th>
                <th scope="col">Type</th>
                <th scope="col">URL</th>
                <th style={{ minWidth: "100px" }} scope="col">
                  Created by
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {getval &&
                getval.map((value) => {
                  return (
                    <>
                      <tr>
                        {/* <th scope="row">1</th> */}
                        <td>{value.title}</td>
                        <td>{value.subtitle}</td>
                        <td>{value.type}</td>
                        <td>{value.url}</td>
                        <td>
                          {value.created_by === "glcAdmin" && "GLC Admin"}
                        </td>
                        <td>
                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              onClick={() => updateResource(value)}
                              type="button"
                              class="btn"
                              style={{ borderColor: "#0b8a0b", color: "#0b8a0b" }}
                            >
                              <i class="fa-solid fa-pen"></i>
                            </button>

                            <button
                              onClick={() => handleDelete(value._id)}

                              type="button"
                              class="btn"
                              style={{ borderColor: "#0b8a0b", color: "#0b8a0b", marginLeft: "5px" }}
                            >
                              <i class="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}

            </tbody>
          </table>
}

        </center>
      </div>
    </>
  );
};

export default View;

import React, { useEffect, useState, useCallback } from "react";
import { createAppConfig, editAppConfig } from "../../apis/appConfig";
import { BASE_URL } from '../../apis';
import Swal from 'sweetalert2';

const AppConfig = () => {


    const [loading, setLoading] = useState(false);

    var token = localStorage.getItem("token").replaceAll(/"/g, "") || ""
       

    const [responseCareerWallet, setResponseCareerWallet] = useState(
        {
            latestIOSVersion: "",
            latestAndroidVersion: "",

            greenLight: {
                greenLight_dev_url: "",
                greenLight_prod_url: "",
            },
            velocity: {
                velocity_dev_url: "",
                velocity_prod_url: "",
            },
            resources: {
                resources_dev_url: "",
                resources_prod_url: "",
            },
        }
    )


    const [isDisabled, setIsDisabled] = useState(false)



    // const fetchCarrerWallet = async () => {

    //     try {
    //         const response = await fetch(`http://44.213.171.19:5010/career-wallet`);
    //         const resposneData = await response.json();
    //         console.log("resposneData>>>>>>>", resposneData)
    //         setResponseCareerWallet(...resposneData)

    //     } catch (error) {
    //         console.error(error);
    //         setLoading(false);
    //     }
    // };

    const fetchCarrerWallet = useCallback(async () => {
        
        try {
            const response = await fetch(`${BASE_URL}/career-wallet`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });


            const resposneData = await response.json();

            console.log('resposneData>>>>>>>fetchCarrerWallet', resposneData);
            setResponseCareerWallet(...resposneData);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, []);


    useEffect(() => {
        fetchCarrerWallet()
    }, [fetchCarrerWallet])




    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()
        try {
            setLoading(true);
            const _id = responseCareerWallet?._id || '';
            const _data = {
                latestIOSVersion: responseCareerWallet?.latestIOSVersion || '',
                latestAndroidVersion: responseCareerWallet?.latestAndroidVersion || '',
                greenLight: {
                    greenLight_dev_url: responseCareerWallet?.greenLight?.greenLight_dev_url || '',
                    greenLight_prod_url: responseCareerWallet?.greenLight?.greenLight_prod_url || '',
                },
                velocity: {
                    velocity_dev_url: responseCareerWallet?.velocity?.velocity_dev_url || '',
                    velocity_prod_url: responseCareerWallet?.velocity?.velocity_prod_url || '',
                },
                resources: {
                    resources_dev_url: responseCareerWallet?.resources?.resources_dev_url || '',
                    resources_prod_url: responseCareerWallet?.resources?.resources_prod_url || '',
                },
            };

            const res = await editAppConfig(_id, _data,token);

            if (res !== undefined || res !== null) {
                Swal.fire({
                    icon: 'success',
                    title: 'App Configuration Edit Successful',
                    showConfirmButton: false,
                    timer: 1900,
                });
            }

            setLoading(false);

            if (res) {
                setIsDisabled(false);
            }

        } catch (error) {
            console.error('Error occurred:', error);
        }
    }, [responseCareerWallet]);



    const handleEdit = () => {
        setIsDisabled(!isDisabled)
    }

    const handleChange = (e) => {

        const { name, value } = e.target;
        if (name.includes(".")) {

            const [outerKey, innerKey] = name.split(".");
            setResponseCareerWallet({
                ...responseCareerWallet,
                [outerKey]: {
                    ...responseCareerWallet[outerKey],
                    [innerKey]: value,
                },
            });
        } else {

            setResponseCareerWallet({
                ...responseCareerWallet,
                [name]: value,
            });
        }

    }






    return (
        <div className="card-body py-3 px-md-5" style={{ backgroundColor: "#f5f5f5" }} >
            <div className="row d-flex justify-content-center" style={{ paddingBottom: "2rem" }}
            >
                <div
                    style={{ backgroundColor: "white", marginTop: "3.6rem", borderRadius: "0px", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)" }}
                    className="col-lg-5  d-flex flex-column align-items-center "
                >
                    <form onSubmit={handleSubmit}>
                        {/* 2 column grid layout with text inputs for the first and last names */}
                        {/* <div className="row"></div> */}



                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "7px" }}>

                            <button
                                onClick={() => handleEdit(responseCareerWallet)}
                                type="button"
                                class="btn"
                                style={{ borderColor: "#0b8a0b", backgroundColor: isDisabled ? "#0b8a0b" : null, color: isDisabled ? "white" : "#0b8a0b", borderRadius: "3px", paddingLeft: "25px", paddingRight: "25px", height: "34px", boxShadow: "none" }}
                            >
                                Edit
                            </button>
                        </div>


                        {/* Versions */}

                        <h5 style={{ marginTop: "16px", fontSize: "17px" }}>Versions</h5>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ marginTop: "6px", minWidth: "48%" }} className="form-outline mb-2">

                                <label

                                    className="form-label"
                                    htmlFor="form3Example3"
                                    style={{ fontSize: "14px" }}
                                >
                                    iOS Version
                                </label>
                                <input
                                    disabled={!isDisabled}
                                    required
                                    type="text"
                                    id="form3Example3"
                                    onChange={handleChange}
                                    name="latestIOSVersion"
                                    placeholder="Version"

                                    value={responseCareerWallet?.latestIOSVersion || ""}

                                    className="form-control"

                                />

                            </div>

                            <div style={{ minWidth: "48%" }} className="form-outline mb-2">
                                <label
                                    style={{ marginTop: "6px", fontSize: "14px" }}

                                    className="form-label"
                                    htmlFor="form3Example4"
                                >
                                    Android Version
                                </label>
                                <input
                                    disabled={!isDisabled}
                                    required
                                    type="text"
                                    name="latestAndroidVersion"
                                    onChange={handleChange}
                                    placeholder="Version"
                                    id="form3Example4"
                                    className="form-control"
                                    value={responseCareerWallet?.latestAndroidVersion || ""}

                                />

                            </div>
                        </div>

                        <hr width="100%" style={{ marginTop: "15px", color: "lightgrey", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)" }} />



                        {/* DevUrls */}


                        <h5 style={{ marginTop: "16px", fontSize: "17px" }}>DevUrls</h5>


                        <div style={{ marginTop: "6px" }} className="form-outline mb-2">

                            <label

                                className="form-label"
                                htmlFor="form3Example3"
                                style={{ fontSize: "14px" }}
                            >
                                GreenLight
                            </label>
                            <input
                                disabled={!isDisabled}
                                type="text"
                                id="form3Example3"
                                onChange={handleChange}
                                name="greenLight.greenLight_dev_url"
                                placeholder="URL"
                                className="form-control"
                                value={responseCareerWallet?.greenLight?.greenLight_dev_url || ""}
                            />

                        </div>

                        <div className="form-outline mb-2">
                            <label
                                style={{ marginTop: "6px", fontSize: "14px" }}
                                className="form-label"
                                htmlFor="form3Example4"

                            >
                                Velocity
                            </label>
                            <input
                                disabled={!isDisabled}
                                required
                                type="text"
                                name="velocity.velocity_dev_url"
                                onChange={handleChange}
                                placeholder="URL"
                                id="form3Example4"
                                className="form-control"
                                value={responseCareerWallet?.velocity?.velocity_dev_url || ""}
                            />

                        </div>


                        <hr width="100%" style={{ marginTop: "20px", color: "lightgrey", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)" }} />



                        {/* ProdUrls */}



                        <h5 style={{ marginTop: "16px", fontSize: "17px" }}>ProdUrls</h5>

                        <div style={{ marginTop: "6px" }} className="form-outline mb-2">

                            <label
                                style={{ fontSize: "14px" }}
                                className="form-label"
                                htmlFor="form3Example3"

                            >
                                GreenLight
                            </label>
                            <input
                                disabled={!isDisabled}
                                required
                                type="text"
                                id="form3Example3"
                                onChange={handleChange}
                                name="greenLight.greenLight_prod_url"
                                placeholder="URL"
                                className="form-control"
                                value={responseCareerWallet?.greenLight?.greenLight_prod_url || ""}
                            />

                        </div>

                        <div className="form-outline mb-2">
                            <label
                                style={{ marginTop: "6px", fontSize: "14px" }}
                                className="form-label"
                                htmlFor="form3Example4"
                            >
                                Velocity
                            </label>
                            <input
                                disabled={!isDisabled}
                                required
                                type="text"
                                name="velocity.velocity_prod_url"
                                onChange={handleChange}
                                placeholder="URL"
                                id="form3Example4"
                                className="form-control"
                                value={responseCareerWallet?.velocity?.velocity_prod_url || ""}
                            />

                        </div>


                        <hr width="100%" style={{ marginTop: "20px", color: "lightgrey", boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)" }} />


                        {/* Resource URL */}


                        <h5 style={{ marginTop: "16px", fontSize: "17px" }}>Resources</h5>

                        <div style={{ marginTop: "6px" }} className="form-outline mb-2">

                            <label

                                className="form-label"
                                htmlFor="form3Example3"
                                style={{ fontSize: "14px" }}
                            >
                                DevUrl
                            </label>
                            <input
                                disabled={!isDisabled}
                                required
                                type="text"
                                id="form3Example3"
                                onChange={handleChange}
                                name="resources.resources_dev_url"
                                placeholder="URL"
                                className="form-control"
                                value={responseCareerWallet?.resources?.resources_dev_url || ""}
                            />

                        </div>

                        <div className="form-outline mb-2">
                            <label
                                style={{ marginTop: "6px", fontSize: "14px" }}
                                className="form-label"
                                htmlFor="form3Example4"
                            >
                                ProdUrl
                            </label>
                            <input
                                disabled={!isDisabled}
                                required
                                type="text"
                                name="resources.resources_prod_url"
                                onChange={handleChange}
                                placeholder="URL"
                                id="form3Example4"
                                className="form-control"
                                value={responseCareerWallet?.resources?.resources_prod_url || ""}
                            />

                        </div>


                        {/* Submit button */}


                        <button
                            disabled={!isDisabled}
                            type="submit"
                            style={{ padding: "8px 235px", marginTop: "8px", backgroundColor: "#0b8a0b", color: "white" }}
                            className="btn  btn-block mb-4 "
                        >
                            {loading ? (
                                <div class="spinner-border text-light" role="status">

                                </div>
                            ) : (
                                "Submit"
                            )}
                        </button>
                        {/* Register buttons */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AppConfig
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

const Signup = () => {
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    ConfirmPassword: "",
    check:"",
  });
  const { username, email, password, ConfirmPassword ,check} = data;

  const changeHandler = (e) => {
    const val = e.target.value;
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (username.length < 5) {
      alert("username must in 5 carecters");
    } else if (password !== ConfirmPassword) {
      alert("password are not matched");
    } else {
      console.log(data);
    }
  };

  return (
    
    <section className="text-center">
    {/* Background image */}
    <div
      className="p-5 bg-image"
      style={{
        backgroundImage:
          'url("https://mdbootstrap.com/img/new/textures/full/171.jpg")',
        height: "200px",
      }}
    />
    {/* Background image */}
    <div
      className="card mx-4 mx-md-5 shadow-5-strong"
      style={{
        marginTop: "-60px",
        background: "hsla(0, 0%, 100%, 0.8)",
        backdropFilter: "blur(30px)",
      }}
    >
      <div className="card-body py-3 px-md-5">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <h2 className="fw-bold mb-5">Sign up now</h2>
            <form  onSubmit={submitHandler}>
              {/* 2 column grid layout with text inputs for the first and last names */}
              <div className="row"></div>
              {/* Email input */}
              <div style={{padding:"8px 100px"}} className="form-outline mb-4">
                <input
                  type="text"
                  id="form3Example3"
                  onChange={changeHandler}
                  name="username"
                  className="form-control"
                />
                <label
                  style={{ marginTop: "6px" }}
                  className="form-label"
                  htmlFor="form3Example3"
                >
                  Username
                </label>
              </div>
              
              <div style={{padding:"8px 100px"}} className="form-outline mb-4">
                <input
                  type="email"
                  id="form3Example3"
                  onChange={changeHandler}
                  name="email"
                  className="form-control"
                />
                <label
                  style={{ marginTop: "6px" }}
                  className="form-label"
                  htmlFor="form3Example3"
                >
                  Email address
                </label>
              </div>
              {/* Password input */}
              <div style={{padding:"8px 100px"}} className="form-outline mb-4">
                <input
                  type="password"
                  id="form3Example4"
                  onChange={changeHandler}
                  name="password"
                  className="form-control"
                />
                <label
                  style={{ marginTop: "6px" }}
                  className="form-label"
                  htmlFor="form3Example4"
                >
                  Password
                </label>
              </div>
              <div style={{padding:"8px 100px"}} className="form-outline mb-4">
                <input
                  type="password"
                  id="form3Example4"
                  onChange={changeHandler}
                  name="ConfirmPassword"
                  className="form-control"
                />
                <label
                  style={{ marginTop: "6px" }}
                  className="form-label"
                  htmlFor="form3Example4"
                >
                  Confirm password
                </label>
              </div>

              {/* Checkbox */}
              <div className="form-check d-flex justify-content-center mb-4">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  defaultValue
                  onChange={changeHandler}
                  id="check"
                  name="check"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="form2Example33">
                  Subscribe to our old letter
                </label>
              </div>
              {/* Submit button */}
              {password !== ConfirmPassword}
              <button
                type="submit" style={{padding:"8px 235px"}}
                className="btn btn-primary btn-block mb-4 "
              >
                signup
              </button>
              {/* Register buttons */}
              <div className="text-center">
              <p>Don't have an account ? <Link to ="/signin">Signin</Link></p>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-facebook-f" />
                </button>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-google" />
                </button>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-twitter" />
                </button>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-github" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  
    );
};

export default Signup;

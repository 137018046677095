import axios from "axios";
import apis from "./index";

// export const createPushData = async (data) => {

//   let formData = new FormData();
//   formData.append("title", data?.title);
//   formData.append("subtitle", data.subtitle || "");
//   formData.append("url", data.url || "");
//   formData.append("created_by", data.created_by || "");
//   formData.append("type", data.type || "");
//   formData.append("address", data.address || "");
//   formData.append("phoneNumber", data.phoneNumber || "");
//   formData.append("audio", data.audio || "");
 
//   // formData.append('contact', data.contact || '');
//   data.uri &&
//     formData.append("audio", {
//       uri: data.uri,
//     });

//   try {
//     let response = await fetch(apis.pushData.upload, {
//       method: "post",
//     //   headers: {
//     //      'Authorization': `Bearer ${token}`,
//     //     'Content-Type': 'application/json',
//     // },
//       body: formData,


//     });
//     response = await response.json();

//     console.log("uploadResources()::API", response);


//     return response;

   
//   } catch (e) {
//     console.log("uploadResources()::API::Error", e);
//     throw e;
//   }
// };


// export const createPushData = async (data) => {

//   let formData = new FormData();
//   formData.append("title", data?.title);
//   formData.append("subtitle", data.subtitle || "");
//   formData.append("url", data.url || "");
//   formData.append("created_by", data.created_by || "");
//   formData.append("type", data.type || "");
//   formData.append("address", data.address || "");
//   formData.append("phoneNumber", data.phoneNumber || "");
//   formData.append("audio", data.audio || "");
 
//   data.uri &&
//     formData.append("audio", {
//       uri: data.uri,
//     });

//   try {
//     let response = await fetch(apis.pushData.upload, {
//       method: "post",
//       // headers: {
//       //   'Authorization': `Bearer ${token}`,
//       // },
//       body: formData,
//     });

//     if (response.ok) {
//       response = await response.json();
//       console.log("uploadResources()::API", response);
//       return response;
//     } else {
//       // Handle non-successful responses (e.g., error responses)
//       console.log("uploadResources()::API::Error", response.status, response.statusText);
//       throw new Error("Request failed");
//     }
//   } catch (e) {
//     console.error("uploadResources()::API::Error", e);
//     throw e;
//   }
// };


export const createPushData = async (data, token) => {
  let formData = new FormData();
  formData.append("title", data?.title);
  formData.append("subtitle", data.subtitle || "");
  formData.append("url", data.url || "");
  formData.append("created_by", data.created_by || "");
  formData.append("type", data.type || "");
  formData.append("address", data.address || "");
  formData.append("phoneNumber", data.phoneNumber || "");
  formData.append("audio", data.audio || "");

  if (data.uri) {
    formData.append("audio", {
      uri: data.uri,
    });
  }

  try {
    const response = await fetch(apis.pushData.upload, {
      method: "post",
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log("uploadResources()::API", responseData);
      return responseData;
    } else {
      // Handle non-successful responses (e.g., error responses)
      const errorText = await response.text();
      console.error("uploadResources()::API::Error", errorText);
      throw new Error(`Request failed with status ${response.status}: ${errorText}`);
    }
  } catch (e) {
    console.error("uploadResources()::API::Error", e);
    throw e;
  }
};

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Nav from "./Nav";

import View from "./View";

import Signin from "./pages/Signin/signin";
import Signup from "./Signup";
import Pushdata from "./pages/Pushdata/Pushdata";
import Notification from "./pages/Notification/Notification";
import AppConfig from "./pages/AppConfig/AppConfig";


function App() {


  return (
    <div>
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/pushnotificaton" element={<Notification />} />
          <Route path="/resources" element={<Pushdata />} />
          <Route path="/view"  element={<View />} />
          <Route path="/appconfig" element={<AppConfig/>}/>
        </Routes>
      </Router>
      {/* <Footer/> */}
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createSignin } from "../../apis/login.services";

const Signin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const { username, password } = data;

  const [getval, setGetval] = useState("");

  const [loading, setLoading] = useState(false)

  const changeHandler = (e) => {
    const val = e.target.value;
    setData({ ...data, [e.target.name]: e.target.value });
  };

  console.log(data.username)
  console.log(data.password)

  const submitHandler = async (e) => {
    e.preventDefault();



    const auth = {
      username: data.username,
      password: data.password,
    };
    setLoading(true)
    try {

      const data = await createSignin(auth);
      console.log(">>>>>>>>>>>>>>>>>", data);
      localStorage.setItem("token", JSON.stringify(data.token));
      setLoading(false)
      // navigate("/view");

      // setGetval(JSON.parse(window.localStorage.getItem("user")));
    } catch (error) {
      setLoading(false)
      console.log("Err", error);
    }
  };

  var token = localStorage.getItem("token")
  console.log(">>>>>>>>>>", token)

  // useEffect(() => { 
  //   if (localStorage.getItem("token") && localStorage.getItem("token") !== undefined) {


  //     navigate("/view");


  //   }
  // }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (token && token !== undefined) {
      navigate("/view");
    }

    if (token === undefined) {
      localStorage.removeItem("token")
    }
  }, [token]);


  return (
    <section className="text-center">
      {/* Background image */}
      <div
        className="p-5 bg-image"
        style={{
          // backgroundImage:
          //   'url("https://mdbootstrap.com/img/new/textures/full/171.jpg")',
          height: "135px",
          backgroundColor: "#f5f5f5"
        }}
      />
      {/* Background image */}
      <div
        className="card mx-4 mx-md-5 shadow-5-strong"
        style={{
          marginTop: "-55px",
          background: "hsla(0, 0%, 100%, 0.8)",
          backdropFilter: "blur(30px)",
        }}
      >
        <div className="card-body py-3 px-md-12">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h2 className="fw-bold mb-5">Login</h2>
              <form onSubmit={submitHandler}>
                {/* 2 column grid layout with text inputs for the first and last names */}
                <div className="row"></div>
                {/* Email input */}
                <div
                  style={{ padding: "8px 100px" }}
                 //style={{padding:"8px 177px 0 177px"}}
                  className="form-outline mb-4"
                >
                  <input
                    required
                    type="username"
                    id="form3Example3"
                    onChange={changeHandler}
                    name="username"
                    className="form-control"
                  />
                  <label
                    style={{ marginTop: "6px" }}
                    className="form-label"
                    htmlFor="form3Example3"
                  >
                    Email address
                  </label>
                </div>
                {/* Password input */}
                <div
                  style={{ padding: "8px 100px" }}
               // style={{padding:"8px 177px 0 177px"}}
                  className="form-outline mb-4"
                >
                  <input
                    required
                    type="password"
                    id="form3Example4"
                    onChange={changeHandler}
                    name="password"
                    className="form-control"
                  />
                  <label
                    style={{ marginTop: "6px" }}
                    className="form-label"
                    htmlFor="form3Example4"
                  >
                    Password
                  </label>
                </div>
                {/* Checkbox */}

                {/* Submit button */}
                <button
                  type="submit"
                  style={{ padding: "8px 235px", backgroundColor: "#0b8a0b", color: "white" }}
                  className="btn btn-block mb-4 "

                >
                  {
                    loading ? <div class="spinner-border text-light" role="status">
                      {/* <span class="sr-only">Loading...</span> */}
                    </div> :
                      "Login"
                  }

                </button>
                {/* Register buttons */}
                {/* <div className="text-center">
        
                  <button
                    type="button"
                    className="btn btn-link btn-floating mx-1"
                  >
                    <i className="fab fa-facebook-f" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link btn-floating mx-1"
                  >
                    <i className="fab fa-google" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link btn-floating mx-1"
                  >
                    <i className="fab fa-twitter" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link btn-floating mx-1"
                  >
                    <i className="fab fa-github" />
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Signin;

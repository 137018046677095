import ENV from "../config"

export const BASE_URL =  ENV.PROD_URL

export default {
  pushData: {
    upload: `${BASE_URL}/upload-resource`,
  },
  signin: {
    auth: `${BASE_URL}/login`,
  },
  sendNotification: {
    notification : `${BASE_URL}/sendUserNotification`
  },
  sendAllNotification: {
    allUsers : `${BASE_URL}/api/v0.6/devices`
  },
  appConfiguration:{
  appConfig:  `${BASE_URL}/career-wallet`,

  }
};

// http://44.213.171.19:5015/getUser?page=2
import React, { useState, useEffect } from 'react';
import { sendNotification } from '../../apis/sendNotification.service';
import { sendAllNotifications } from '../../apis/sendAllNotifications.service';
import { BASE_URL } from '../../apis';
import Swal from 'sweetalert2';

const Notification = () => {
  const [data, setData] = useState({ title: '', description: '' });
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);




  var token = localStorage.getItem("token").replaceAll(/"/g,"") || ""



var selectedUsersCount =  selectedUsers && selectedUsers?.length


  const fetchNotifications = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/getUser?page=${pageNumber}`,{
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setNotifications(data?.data);
      setTotalPages(data?.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const toggleUserSelection = (userID) => {
    const isSelected = selectedUsers.includes(userID);
    if (isSelected) {
      setSelectedUsers(selectedUsers?.filter((id) => id !== userID));
    } else {
      setSelectedUsers([...selectedUsers, userID]);
    }
  };

  const isUserSelected = (userID) => {
    return selectedUsers.includes(userID);
  };

  const toggleSelectAllUsers = () => {
    setSelectAllUsers(!selectAllUsers);
    if (!selectAllUsers) {
      const alluserIDs = notifications.map((notification) => notification?.userID);
      setSelectedUsers(alluserIDs);
    } else {
      setSelectedUsers([]);
    }
  };

  const paginate = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    fetchNotifications(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };



  const sendNotification1 = async () => {
// const data = selectedUsers.map((userId) =>({userId}))

//     console.log(data)

    try {
      const data1 = {
        data: selectedUsers.map((userId) => ({
          userId,
        })),
        title: data?.title,
        body: data?.description,
      };

      const response = await sendNotification(data1,token);

      Swal.fire({
       
        icon: 'success',
        title: 'Notification Sent Successfully',
        showConfirmButton: false,
        timer: 1800
      })

      console.log('response', response);
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotificationToAll = async () => {
    try {
      const data1 = {
        title: data?.title,
        description: data?.description,
      };

      const response = await sendAllNotifications(data1,token);

      Swal.fire({
       
        icon: 'success',
        title: 'notification sent successfully',
        showConfirmButton: false,
        timer: 1800
      })
      console.log('response', response);
    } catch (error) {
      console.error(error);
    }
  };

  const isAllUsersSelected = () => {
    return selectedUsers?.length === notifications?.length;
  };

  const renderPagination = () => {
    const renderPageButtons = () => {
      const buttons = [];
      const maxVisibleButtons = 5; // Maximum number of buttons to display

      buttons.push(
        <li
          key="prev"
          className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePrevPage}
        >
          <button className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
      );
      buttons.push(
        <li
          key={1}
          className={`page-item ${currentPage === 1 ? 'active' : ''}`}
          onClick={() => paginate(1)}
        >
          <button className="page-link">1</button>
        </li>
      );

      let startPage = Math.max(2, currentPage - Math.floor(maxVisibleButtons / 2));
      let endPage = Math.min(totalPages - 1, startPage + maxVisibleButtons - 1);

      if (startPage > 2) {
        buttons.push(
          <li key="ellipsis-start" className="page-item disabled">
            <button className="page-link">&hellip;</button>
          </li>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <li
            key={i}
            className={`page-item ${currentPage === i ? 'active' : ''}`}
            onClick={() => paginate(i)}
          >
            <button className="page-link">{i}</button>
          </li>
        );
      }

      if (endPage < totalPages - 1) {
        buttons.push(
          <li key="ellipsis-end" className="page-item disabled">
            <button className="page-link">&hellip;</button>
          </li>
        );
      }
      buttons.push(
        <li
          key={totalPages}
          className={`page-item ${currentPage === totalPages ? 'active' : ''}`}
          onClick={() => paginate(totalPages)}
        >
          <button className="page-link">{totalPages}</button>
        </li>
      );
      buttons.push(
        <li
          key="next"
          className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={handleNextPage}
        >
          <button className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      );

      return buttons;
    };

    if (totalPages <= 1) {
      return null;
    }

    return (
      <ul className="pagination justify-content-center">
        {renderPageButtons()}
      </ul>
    );
  };

  return (
    <div className="card-body py-3 px-md-5 w-100">
      <div className="row d-flex justify-content-center ">
        <div style={{ paddingTop: '22px' }} className="col-lg-8 d-flex flex-column align-items-center">
          <form className='col-12'>
            <div className="row"></div>
            <div className="form-outline mb-4 d-flex flex-column">
            <label style={{ marginTop: '6px' }} className="form-label" htmlFor="form3Example3">
                Title
              </label>
              <input
                type="text"
                id=""
                value={data?.title}
                onChange={handleChange}
                name="title"
                placeholder="Title"
                className="form-control"
              />
              {/* <label style={{ marginTop: '6px' }} className="form-label" htmlFor="form3Example3">
                Title
              </label> */}
            </div>
            <div className="form-outline mb-4 d-flex flex-column">
            <label style={{ marginTop: '6px' }} className="form-label" htmlFor="form3Example4">
                Description
              </label>
              <textarea
                type="text"
                name="description"
                value={data?.description}
                onChange={handleChange}
                placeholder="Description"
                id=""
                className="form-control"
              />
              {/* <label style={{ marginTop: '6px' }} className="form-label" htmlFor="form3Example4">
                Description
              </label> */}
            </div>
          </form>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          {loading ? (
            <div className="spinner-border" style={{color:"#0b8a0b"}} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
      
            <div className="col-8" style={{padding:"0 5px 0 5px"}} >
              <table className="table  table-bordered p-2">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="d-flex flex-row">
                        <input
                      
                          type="checkbox"
                          checked={isAllUsersSelected()}
                          onChange={toggleSelectAllUsers}
                          className="m-10  mx-3"
                        />
                        <div className="ml-3">S.NO</div>
                      </div>
                    </th>
                    <th scope="col">userID</th>
                    <th scope="col">UserName</th>
                    <th scope="col">FirstName</th>
                    <th scope="col">LastName</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications.map((notification, index) => (
                    <tr key={notification?.id}>
                      <td>
                        <input
                          type="checkbox"
                          className='mx-3'
                          checked={isUserSelected(notification?.userID)}
                          onChange={() => toggleUserSelection(notification?.userID)}
                        />{' '}
                        {index + 1}
                      </td>
                      <td>{notification?.userID ? notification?.userID : "N/A"}</td>
                      <td>{notification?.userName ? notification?.userName : "N/A"}</td>
                      <td>{notification?.firstName ? notification?.firstName : "N/A"}</td>
                      <td>{notification?.lastName ? notification?.lastName : "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="mt-3">{renderPagination()}</div>

        <div className="d-flex justify-content-center mt-3">
          
          <button
           disabled={!data?.title || !data?.description || selectedUsersCount <= 0 }
           type="button" 
           style={{backgroundColor:"#0b8a0b",color:"white"}}
           className="btn me-2" 
           onClick={sendNotification1}>
            Send Notification
          </button>

          <button
            type="button"
            className="btn"
            style={{color:"#0b8a0b",borderColor:"#0b8a0b"}}
            onClick={sendNotificationToAll}
            disabled={!isAllUsersSelected() || !data?.title || !data?.description}
          >
            Send Notification To All Users
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;

import React, { useEffect, useState } from "react";

import { Link, useNavigate } from 'react-router-dom';

const Nav = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   const href = window.location.href;
  //   if (href.includes("/resources")) {
  //     setLoggedIn(true);
  //   } else setLoggedIn(false);
  // }, [localStorage.getItem("token"), window.location.href, loggedIn]);



  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/")
    }
  })


  const handlePushNotification = () => {
    navigate("/pushnotificaton");

  }

  const handleAppConfig = () => {
    navigate("/appconfig");
  }

  return (
    <div style={{ height: "60px", backgroundColor: "#0b8a0b" }} className="d-flex flex-row text-end p-2 ">
      {/* <div className='m-2'>
         <Link to="/pushnotificaton" className=' text-light text-decoration-none'>Push notification </Link>
       </div> */}



      {/* {!localStorage.getItem("token") && (
           <div className="m-2 ">
          <Link to="/" className="text-light text-decoration-none">
            Login
          </Link>
          </div>
        )} */}

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "10px", paddingBottom: "10px" }}>
        <div onClick={() => navigate("/view")}>
          <img height={30} src="https://mygreenlight.biz/assets/images/logo-white.png" />



        </div>

<div style={{display:"flex",gap:"20px"}}>
        <div
            onClick={handlePushNotification}
            
            type="button"
            className="text-light text-decoration-none"

          >
            Push Notification
          </div>

          <div
            onClick={handleAppConfig}
            style={{ borderColor: "#0b8a0b", color: "#0b8a0b", borderRadius: "3px" }}
            type="button"
            className="text-light text-decoration-none"

          >
            App Configuration
          </div>

          {localStorage.getItem("token") ? null :
          <div>
            <Link to="/" className="text-light text-decoration-none">
              Login
            </Link>
          </div>
        }
          </div>
          

      </div>

    </div>
  );
};

export default Nav;

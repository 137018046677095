import React, { useEffect, useState,useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { createPushData } from "../../apis/pushdata.service";
import Swal from 'sweetalert2';

const Pushdata = () => {
  const navigate = useNavigate();
  const [arr, setarr] = useState([]);

   var token = localStorage.getItem("token").replaceAll(/"/g,"") || ""

  const [getval, setGetval] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null)

  const [data, setData] = useState({
    title: "",
    subtitle: "",
    url: "",
    select: "",
    address: "",
    number: "",
    file: ""
  });

  



  const handleFile = (e) => {
    setFile(e.target.files[0])
    console.log(e)
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);



  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const URL_REGEX = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/i;

  //   if (data.select !== "Contact" && !URL_REGEX.test(data.url)) {

  //     alert('Please enter a valid URL');

  //   }

  //   else {

  //     const userDetails = {
  //       title: data.title,
  //       subtitle: data.subtitle,
  //       url: data.url,
  //       type: data.select,
  //       created_by: "glcAdmin",
  //       address: data.address,
  //       phoneNumber: data.number,
  //       audio: file
  //     };
  //     setLoading(true);

  //     try {
  //       const res = await createPushData(userDetails);

  //       setLoading(false);

  //       setarr([...arr, userDetails]);

  //       if (res && !loading) {
  //         Swal.fire({

  //           icon: 'success',
  //           title: 'Resources Created Successfully',
  //           showConfirmButton: false,
  //           timer: 1800
  //         })
  //       }


  //     } catch (error) {
  //       setLoading(false);
  //       alert("Something went wrong");
  //     }

  //     setTimeout(()=>{
  //       navigate("/view");
  //     },1900)

     

  //   }
  // };

  const handleSubmit = useCallback(async (e) => {

      e.preventDefault();

    const URL_REGEX = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/i;

    if (data.select !== "Contact" && !URL_REGEX.test(data.url)) {
      alert('Please enter a valid URL');
      return

    }
    
      var userDetails = {
        title: data?.title,
        subtitle: data?.subtitle,
        url: data?.url,
        type: data?.select,
        created_by: "glcAdmin",
        address: data?.address,
        phoneNumber: data?.number,
        audio: file
      };
      setLoading(true);

      try {

   
        const res = await createPushData(userDetails,token);

        setLoading(false);

        setarr([...arr, userDetails]);

        if (res && !loading) {
          Swal.fire({

            icon: 'success',
            title: 'Resources Created Successfully',
            showConfirmButton: false,
            timer: 1800
          })
        }


      } catch (error) {
        setLoading(false);
       // alert("Something went wrong");
      }

      setTimeout(()=>{
        navigate("/view");
      },1900)

    
  }, [data, file, loading,navigate]);

  return (
    <div className="card-body py-3 px-md-5" style={{backgroundColor:"#f5f5f5",height:"100vh"}} >
      <div  className="row d-flex justify-content-center" 
      >
        <div
          style={{backgroundColor:"white",marginTop:"5rem",borderRadius:"0px",boxShadow:"0px 0px 3px rgba(0, 0, 0, 0.1)" }}
          className="col-lg-5  d-flex flex-column align-items-center"
        >
          <form onSubmit={handleSubmit}>
            {/* 2 column grid layout with text inputs for the first and last names */}
            <div className="row"></div>


            <div className="form-outline mb-2" style={{ marginTop: "20px" }}>

              <label
                style={{ marginTop: "6px" }}
                className="form-label"
                htmlFor="form3Example3"
              >
                Select Category
              </label>
              <select required
                class="form-select"
                onChange={handleChange}
                id="select"
                name="select"
              >
                <option disabled>
                  Select type
                </option>
                <option >Video</option>
                <option >Article</option>
                <option >Contact</option>
              </select>
            </div>

            {/* Email input */}
            <div className="form-outline mb-2">
              <label
                style={{ marginTop: "6px" }}
                className="form-label"
                htmlFor="form3Example3"
              >
                Title
              </label>
              <input
                required
                type="text"
                id="form3Example3"
                onChange={handleChange}
                name="title"
                placeholder="Title"
                className="form-control"
              />

            </div>
            {/* Password input */}
            <div className="form-outline mb-2">
              <label
                style={{ marginTop: "6px" }}
                className="form-label"
                htmlFor="form3Example4"
              >
                Subtitle
              </label>
              <input
                type="text"
                name="subtitle"
                onChange={handleChange}
                placeholder="Subtitle"
                id="form3Example4"
                className="form-control"
              />

            </div>
            {/* Checkbox */}

            {data.select !== "Contact" &&

              <div className="form-outline mb-2">
                <label
                  style={{ marginTop: "6px" }}
                  className="form-label"
                  htmlFor="form3Example4"
                >
                  URL
                </label>
                <input
                  required
                  type="text"
                  name="url"
                  onChange={handleChange}
                  placeholder="Url"
                  id="form3Example4"
                  className="form-control"
                />

              </div>
            }

            {/* <div className="form-outline mb-4">
            <label
                style={{ marginTop: "6px" }}
                className="form-label"
                htmlFor="form3Example4"
              >
                Choose Thumbnail
              </label>
              <input required
                type="file"
                name="file"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setFile(event.target.files[0])
                  
                }}
        
                placeholder="file"
                id="form3Example4"
                className="form-control"
              />
            
            </div> */}

            {/* select type */}

            {data.select === "Contact" ? (
              <>
                <div className="form-outline mt-2 mb-2">
                  <label
                    style={{ marginTop: "6px" }}
                    className="form-label"
                    htmlFor="form3Example4"
                  >
                    Address
                  </label>
                  <input

                    required
                    type="text"
                    name="address"
                    placeholder="Address"
                    id="form3Example4"
                    onChange={handleChange}
                    className="form-control"
                  />

                </div>

                <div className="form-outline mb-2">
                  <label
                    style={{ marginTop: "6px" }}
                    className="form-label"
                    htmlFor="form3Example4"
                  >
                    Phone Number
                  </label>

                  {/* <span class="input-group-text" id="basic-addon1">
                      +1
                    </span> */}
                  <div style={{ display: "flex" }}>
                    <div style={{
                      backgroundColor: "#e9ecef", width: "40px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"
                      , display: "flex", alignItems: "center", justifyContent: "center", color: "#212529"
                    }}>
                      +1</div>
                    <input required
                      type="text"
                      name="number"
                      placeholder="Phone Number"
                      id="form3Example4"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>


                </div>


              </>
            ) : null}

            {/* Submit button */}
            <button
              type="submit"
              style={{ padding: "8px 235px", marginTop: "8px",backgroundColor:"#0b8a0b",color:"white" }}
              className="btn  btn-block mb-4 "
            >
              {loading ? (
                <div class="spinner-border text-light" role="status">

                </div>
              ) : (
                "Submit"
              )}
            </button>
            {/* Register buttons */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pushdata;

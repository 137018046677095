import axios from "axios";
import apis from "./index";

export const createAppConfig = async (data) => {
    try {
      let response = await fetch(apis.appConfiguration.appConfig, {
      method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
      response = await response.json();
  
      console.log("createAppConfig()::API", response);
      return response;
    } catch (e) {
      console.log("createAppConfig()::API::Error", e);
      throw e;
    }
  };

  export const editAppConfig = async (id, data,modifyToken) => {

    try {
      let response = await fetch(apis.appConfiguration.appConfig + `/${id}`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${modifyToken}`,
        },
        body: JSON.stringify(data),
      });
      response = await response.json();
  
      console.log("editAppConfig()::API", response);
      return response;
    } catch (e) {
      console.log("editAppConfig()::API::Error", e);
      throw e;
    }
  };

//   export const fetchAppConfig = async () => {
//     try {
//         const response = await fetch(`${BASE_URL}/career-wallet`);
//         const data = await response.json();
//         return data
       
//       } catch (error) {
//         console.error(error);
      
//       }
//   };
import axios from "axios";
import apis from "./index";

export const sendNotification = async (data,token) => {
  try {
    let response = await fetch(apis.sendNotification.notification, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
        body: JSON.stringify(data),
    });
    response = await response.json();

    console.log("uploadResources()::API", response);
    return response;
  } catch (e) {
    console.log("uploadResources()::API::Error", e);
    throw e;
  }
};
